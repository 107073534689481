import React from "react";

const sceneNames = ['Office', 'Living Room', 'Dinner Party'];

const SceneButton = ({ sceneIndex, sceneName, onSceneClick, disabled }) => {
  if (!disabled) {
  return (
    <button 
      className={`
        sceneButton
        ${sceneNames[sceneIndex] === sceneName && 'sceneButtonSelected'}
      `}
      onClick={onSceneClick}
      disabled={disabled}
    >
      {sceneName}
    </button>
  );
  } else {
    return (
      <button 
        className='sceneButton sceneButtonDisabled'
        onClick={onSceneClick}
        disabled={disabled}
      >
        {sceneName}
      </button>
    );
  }
};

export default SceneButton;
