import React from "react";

import play from '../assets/images/play.png';
import pause from '../assets/images/pause.png';

const PlayPauseButton = ({ onPauseClick, paused }) => {
  
  return (
    <button 
      className="pauseButton"
      onClick={onPauseClick}
    >
      <img 
        src={paused ? pause : play}
        alt={paused ? 'pause' : 'play'}
      />
    </button>
  );
};
  
export default PlayPauseButton;