import React from "react";
import '../styles.css'
import aidOn from "../assets/images/aid-on.png"
import aidOff from "../assets/images/aid-off.png"
import aidOnHover from "../assets/images/aid-on-hover.png"
import aidOffHover from "../assets/images/aid-off-hover.png"

export const AidButton = (props) => {
  return (
    <div>
      <img 
        src={props.aided ? aidOn : aidOff} 
        style={{width: "50%", height: "50%", cursor: 'pointer' }}
        onClick={props.onAidClick} 
        onMouseOver={e => (props.aided ? e.currentTarget.src = aidOnHover : e.currentTarget.src = aidOffHover)}
        onMouseOut={e => (props.aided ? e.currentTarget.src = aidOn : e.currentTarget.src = aidOff)}
      />
    </div>
  );
};
