import React from "react";


export const SubmitButton = ({ buttonText, onSubmitClick, disabled }) => {
  return (
    <button 
      className={disabled ? 'submitButtonDisabled' : 'submitButton'}
      onClick={onSubmitClick}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
};
