import { useState } from 'react';
import Cookies from 'js-cookie';

import Header from './components/Header';
import SceneButton from './components/SceneButton';
import LeadForm from './components/LeadForm';
import SalesForm from './components/SalesForm';
import VideoPlayer from './components/VideoPlayer';
import AudioPlayer from './components/AudioPlayer';
import Controls from './components/Controls';
import HelpText from './components/HelpText';
import Instructions from './components/Instructions';

import useSalesForm from './hooks/useSalesForm';
import useForm from './hooks/useForm';

import arrow from './assets/images/arrow.png';
import home from './assets/images/home.png';
import question from './assets/images/question-mark.png';

import livingRoomClearVid from "./assets/videos/livingroom-clear-purchased.mov";
import dinnerPartyClearVid from "./assets/videos/dinnerparty-clear-purchased.mov";
import officeClearVid from "./assets/videos/office-clear-purchased.mov";

import howWorkVid from "./assets/videos/how-does-it-work.mp4";

const sceneNames = ['Office', 'Living Room', 'Dinner Party'];

const sceneClearVids = [officeClearVid, livingRoomClearVid, dinnerPartyClearVid];

const App = () => {

  const [sceneIndex, setSceneIndex] = useState(null);
  const [aided, setAided] = useState(true);
  const [paused, setPaused] = useState(true);

  const [aidedClicked, setAidedClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showHelpText, setShowHelpText] = useState(false);
  const [showHowWork, setShowHowWork] = useState(false);

  const [leadCaptured, setLeadCaptured] = useState(false);

  const { postFormData } = useForm();

  const { postSalesFormData } = useSalesForm();

  //TODO: make these environmental variable read from Secret Manager
  const salesTool = window.location.pathname === '/uD3B1xTmoi7EkdNr'
  const skipForm = window.location.pathname === '/WJUBzEz98dC6alJJ'

  const hasSubmittedForm = Cookies.get('formSubmitted') || skipForm

  if (sceneIndex == null) {
    analytics.track('Simulator Landing Page View', {});
  }
    
  const handleSceneClick = (i, formData) => {
    // when they switch scenes, have it default to unaided
    setAided(false)

    // hide help text from previous scene if shown
    setShowHelpText(false); 
    setShowHowWork(false);

    setSceneIndex(i)

    // show form if it's their first time accessing a scene
    // NOTE: to show a form their first time switching scenes just change sceneIndex != null
    if (!showForm && sceneIndex == null && !leadCaptured && !hasSubmittedForm) {
      analytics.track('Simulator FormView', {});
      setShowForm(true);
      setPaused(false);
    } else {
        analytics.track('Simulator SceneClick ' + sceneNames[i], {});
    }
    
    if (formData) {
      setPaused(!paused);
      setLeadCaptured(true);
      setShowForm(false);
      // use the data from the form to post to Audicus API
      if (salesTool) { 
        postSalesFormData(formData);
      } else {
        postFormData(formData)
      }

    }

  } 
  
  const handleAidClick = (aided, i) => {
    // set aidedClicked to true first time user clicks aid button
    setAidedClicked(true);
    // help text will show up after delay when aid is turned on
    if (!aided) {
      setTimeout(() => setShowHelpText(true), 500);
    } else {
      setShowHelpText(false);
    }

    setAided(!aided);

    // removing this tracking event as it doesn't seem to log correctly 2023-10-05 -JP
    // const aidedString = aided ? 'On' : 'Off';
    // analytics.track('Simulator AidClick ' + aidedString + ' ' + sceneNames[i], {});
  }

  const handlePauseClick = (paused) => {
    setPaused(!paused)
  }

  const handleHomeClick = () => {
    // reset app state
    setSceneIndex(null);
    setAidedClicked(false);
    setShowHelpText(false);
    setShowHowWork(false);
    setShowForm(false);
    setPaused(true);

  }

  const handleHowWorkClick = () => {
    setShowHowWork(true);
    setSceneIndex(null);
    setAidedClicked(false);
    setShowHelpText(false);
  }


  return (
    <main className="top-container">
      {!skipForm && (<Header />)}
      
      <div className="simulator">

        <AudioPlayer 
          sceneIndex={sceneIndex}
          aided={aided}
          paused={paused}
        />

        <div className="videoContainer">

          <VideoPlayer 
            videoUrl={
              sceneIndex !== null 
                ? sceneClearVids[sceneIndex] 
                : showHowWork ? howWorkVid : null
            } 
            aided={sceneIndex !== null ? aided : true}  
            paused={sceneIndex !== null ? paused : true}  
          />

          {sceneIndex == null && !showHowWork && (
            <div className="single-button-container">
              <button 
                className="question-button"
                onClick={() => handleHowWorkClick()}
              >
                <img src={question} alt="question button" />
              </button>
            </div>
          )}

          {((sceneIndex == null && showHowWork) || (sceneIndex !== null && showForm)) && (
            <div className="single-button-container">
              <button 
                className="home-button"
                onClick={() => handleHomeClick()}
              >
                <img src={home} alt="home button" />
              </button>
            </div>
          )}

          {sceneIndex !== null && !showForm && (
            <div 
              className="aided-indicator"
              style={{ background: aided ? '#F2A30D' : '#EB4926' }}
            >
              <p>Hearing Aid {aided ? 'On' : 'Off'}</p>
            </div>
          )}

          {sceneIndex !== null && !showForm && (
            <Controls 
              aided={aided}
              paused={paused}
              sceneIndex={sceneIndex}
              handleAidClick={handleAidClick}
              handlePauseClick={handlePauseClick}
              handleHomeClick={handleHomeClick}
              handleHowWorkClick={handleHowWorkClick}
            />
          )}

          {sceneIndex !== null && !showForm && (
            <div 
              className={`
                tooltip-container
                ${aidedClicked ? 'tooltip-container-fade-out' : ''}
            `}
            >
              <p>Click the button on the right to turn the hearing aid on.</p>
              <img src={arrow} alt="arrow" />
            </div>
          )}

          {sceneIndex !== null && aidedClicked && (
            <div className="click-scene-info click-scene-info-fade-in">
              <p>Click a new environment below to hear more.</p>
            </div>
          )}

          {sceneIndex !== null && showHelpText && !showForm && (
            <HelpText 
              showHelpText={showHelpText} 
              sceneIndex={sceneIndex}
            />
          )}


          {sceneIndex !== null && showForm && salesTool && (
            <SalesForm handleSubmit={(formData) => handleSceneClick(1, formData)} />
          )}

          {sceneIndex !== null && showForm && !salesTool && (
            <LeadForm handleSubmit={(formData) => handleSceneClick(1, formData)} />
          )}

          <Instructions sceneIndex={sceneIndex} showHowWork={showHowWork} />
        </div>

        <div className="sceneButtonContainer">
          {sceneNames.map((scene, i) => (
            <SceneButton
              key={`scene-button-${i}`}
              sceneName={scene}
              sceneIndex={sceneIndex}
              onSceneClick={() => handleSceneClick(i)}
              disabled={(i !== 1 && sceneIndex==null && !leadCaptured &&!hasSubmittedForm) || (i !== 1 && showForm)}
            />
          ))}
        </div>

      </div>
    </main>
  );
}

export default App;