import { SubmitButton } from './SubmitButton';
import useForm from '../hooks/useForm';

const LeadForm = ({ handleSubmit }) => {
  const { formData, createFormInput, isFormValid } = useForm();

  return (
    <div className='lead-capture-container'>
                
      <div className='header-form-container'>  
        <span className='header-form-text'> 
          {/* Thanks for listening! To unlock more scenes and 
          receive a $100 welcome coupon, please enter your contact details. */}
          Ready to hear the difference of Audicus hearing aids? Enter your info 
          to receive a welcome coupon and get started!
        </span>
      </div>

      <form className='form-container'>
        {Object.keys(formData).map(name => createFormInput(name))}
      </form>

      <div className='submit-form-container'>
        <SubmitButton 
          buttonText={'Submit'}
          onSubmitClick={() => handleSubmit(formData)}
          disabled={!isFormValid}
        />
      </div>

      <div className='footer-container'>  
        <span className='footer-form-text'> 
          By clicking submit, you agree to receive text messages and 
          emails from Audicus. View our 
          <a className="link-text" href="https://www.audicus.com/privacy-policy/">
            {' '}Privacy Policy
          </a> 
          {' '}and{' '} 
          <a className="link-text" href="https://www.audicus.com/terms-of-service/">
            Terms of Service{' '}
          </a> 
          for more. We will not sell your data. 
        </span>
      </div>

    </div>
  );
}

export default LeadForm;