import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import "./styles.css";

import App from './app';

ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <App />
  </StrictMode>
);