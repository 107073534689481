import { SubmitButton } from './SubmitButton';
import useSalesForm from '../hooks/useSalesForm';

const LeadForm = ({ handleSubmit }) => {
  const { formData, createFormInput, isFormValid } = useSalesForm();

  return (
    <div className='lead-capture-container'>
                
      <div className='header-sales-form-container'>  
        <span className='header-form-text'> 
          {/* Thanks for listening! To unlock more scenes and 
          receive a $100 welcome coupon, please enter your contact details. */}
          Ready to hear the difference of Audicus hearing aids? Enter your email 
          below to get started with the simulator!
        </span>
      </div>

      <form className='sales-form-container'>
        {Object.keys(formData).map(name => createFormInput(name))}
      </form>

      <div className='submit-sales-form-container'>
        <SubmitButton 
          buttonText={'Submit'}
          onSubmitClick={() => handleSubmit(formData)}
          disabled={!isFormValid}
        />
      </div>

      <div className='footer-sales-container'>  
        <span className='footer-form-text'> 
          By clicking submit, you agree to receive text messages and 
          emails from Audicus. View our 
          <a className="link-text" href="https://www.audicus.com/privacy-policy/">
            {' '}Privacy Policy
          </a> 
          {' '}and{' '} 
          <a className="link-text" href="https://www.audicus.com/terms-of-service/">
            Terms of Service{' '}
          </a> 
          for more. We will not sell your data. 
        </span>
      </div>

    </div>
  );
}

export default LeadForm;