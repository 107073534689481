import React from 'react';

// OPEN EAR UNAIDED AUDIO
import livingRoomUnaided from "../assets/audio-unaided/Living Room_1Tv2_Audicus_KemarOpenEar_Normal.mp3";
import dinnerPartyUnaided from "../assets/audio-unaided/Dinner Party_3Tv1_Audicus_KemarOpenEar_Normal.mp3";
import officeUnaided from "../assets/audio-unaided/Office_2Tv1_Audicus_KemarOpenEar_Normal.mp3";
// import cafeUnaided from "../assets/audio-unaided/Cafe 1_1Tv2_Audicus_KemarOpenEar_Normal.mp3";
// import trainUnaided from "../assets/audio-unaided/Train Station_1Tv2_Audicus_KemarOpenEar_Normal.wav";
// import churchUnaided from "../assets/audio-unaided/Church 1_1Tv1_Audicus_KemarOpenEar_Normal.wav";

// OMNI 2C1 AUDIO
import livingRoomAided from "../assets/audio-Omni2C1/Living Room_1Tv2_Audicus_Omni2_Cust1_Normal.mp3";
import dinnerPartyAided from "../assets/audio-Omni2C1/Dinner Party_3Tv1_Audicus_Omni2_Cust1_Normal.mp3";
import officeAided from "../assets/audio-Omni2C1/Office_2Tv1_Audicus_Omni2_Cust1_Normal.mp3";
// import cafeAided from "../assets/audio-Omni2C1/Cafe 1_1Tv2_Audicus_Omni2_Cust1_Normal.mp3";
// import trainAided from "../assets/audio-Omni2C1/Train Station_1Tv2_Audicus_Omni2_Cust1_Normal.mp3";
// import churchAided from "../assets/audio-Omni2C1/Church 1_1Tv1_Audicus_Omni2_Cust1_Normal.mp3";

const aidedAudioFiles = [officeAided, livingRoomAided, dinnerPartyAided];
const unaidedAudioFiles = [officeUnaided, livingRoomUnaided, dinnerPartyUnaided];

const AudioPlayer = ({ sceneIndex, aided, paused }) => {
  return (
    <>
      {/* TODO: this is janky, figure out a way to not do this */}
      {sceneIndex === 0 && (
        <>
          <audio
            id="aidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={!aided || !paused}
          >
            <source src={aidedAudioFiles[0]} type="audio/mpeg"/>
          </audio>

          <audio
            id="unaidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={aided || !paused}
          >
            <source src={unaidedAudioFiles[0]} type="audio/mpeg"/>
          </audio>
        </>
      )}
      {sceneIndex === 1 && (
        <>
          <audio
            id="aidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={!aided || !paused}
          >
            <source src={aidedAudioFiles[1]} type="audio/mpeg"/>
          </audio>

          <audio
            id="unaidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={aided || !paused}
          >
            <source src={unaidedAudioFiles[1]} type="audio/mpeg"/>
          </audio>
        </>
      )}
      {sceneIndex === 2 && (
        <>
          <audio
            id="aidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={!aided || !paused}
          >
            <source src={aidedAudioFiles[2]} type="audio/mpeg"/>
          </audio>

          <audio
            id="unaidedAudio" 
            preload="true"
            loop
            autoPlay
            muted={aided || !paused}
          >
            <source src={unaidedAudioFiles[2]} type="audio/mpeg"/>
          </audio>
        </>
      )}
    </>
  );
};

export default AudioPlayer;
