
import { AidButton } from "./AidButton";
import PlayPauseButton from "./PlayPauseButton";

import home from '../assets/images/home.png';
import question from '../assets/images/question-mark.png';

const Controls = ({ 
  aided,
  paused, 
  sceneIndex, 
  handleAidClick, 
  handlePauseClick,
  handleHomeClick,
  handleHowWorkClick,
}) => {
  return (
    <div className="aid-container">
      <AidButton 
        aided={aided} 
        onAidClick={() => handleAidClick(aided, sceneIndex)}
      />
      <div className="pauseButtonContainer">
        <PlayPauseButton 
          paused={paused} 
          onPauseClick = {() => handlePauseClick(paused)}
        />
      </div>
      <button 
        className="home-button"
        onClick={() => handleHomeClick()}
      >
        <img src={home} alt="home button" />
      </button>
      <button 
        className="question-button"
        onClick={() => handleHowWorkClick()}
      >
        <img src={question} alt="question button" />
      </button>
    </div>
  );
}

export default Controls;