import React from 'react';
import ReactPlayer from 'react-player';

import defaultBackground from '../assets/images/default_background_image.png';
import useWindowSize from '../hooks/useWindowSize';

const VideoPlayer = ({ videoUrl, aided, paused }) => {
  const { width } = useWindowSize();

  return (
    <div className="video-player-container">
      <div className="video-blur-container">
        {videoUrl ? (
          <ReactPlayer
            playsinline
            url={videoUrl}
            playing={!paused ? false : true}
            loop={true}
            width={`${width > 1024 ? '100%' : 'auto'}`}
            height="100%"
            className="video-player"
            style={!aided ? { filter: 'blur(5px)'} : {}}
          />
        ) : (
          <img 
            src={defaultBackground} 
            style={{ width: `${width > 1024 ? '100%' : 'auto'}` }}
            alt="man sitting on couch looking at phone" 
          />
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
