const helpText = [
  {
    name: 'leftmost button',
    sideText: 'Notice How',
    mainText: 'It\'s easier to hear the person talking to you.',
  },
  {
    name: 'middle button',
    sideText: 'Notice How',
    mainText: 'It takes less effort to understand the conversation.',
  },
  {
    name: 'rightmost button',
    sideText: 'Listen For',
    mainText: 'Ability to hear voices over background noise.',
  }
];

const HelpText = ({ showHelpText, sceneIndex }) => {
  return (
    <div 
      className={`
        help-text-container
        ${showHelpText ? 'help-text-fade-in' : 'help-text-fade-out'}
      `}
    >
      <div className='side-text'>
        <p>{helpText[sceneIndex].sideText}</p>
      </div>
      <p>{helpText[sceneIndex].mainText}</p>
    </div>
  );
}

export default HelpText;