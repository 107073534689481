const Instructions = ({ sceneIndex, showHowWork }) => {
  return (
    <div 
      className={`
        instructions-container
        ${(sceneIndex !== null || showHowWork) && (!showHowWork ? 'instructions-fade-out' : '')}
      `}
    >
      {!showHowWork && sceneIndex == null ? (
        <div className="instructions-text-container">
          <p style={{ marginBottom: '20px' }}>
            Welcome to the Audicus Hearing Aid Simulator!<br/><br/>
            Our brand new simulator uses real in-ear recordings.
            That means you can experience what it's <i>really</i> like to wear Audicus hearing aids
            without leaving your home.
          </p>
          <p>
            Make sure you are wearing headphones, then click Living Room below to get started.
          </p>
        </div>
      ) : (sceneIndex == null) ? (
        <div className="instructions-text-container">
          <p>
            The simulator uses real in-ear recordings done by an independent acoustics 
            lab with the <strong> Audicus Omni Series 2 hearing aid</strong>, programmed 
            to typical age-related hearing loss.
          </p>
          <p> 
            Audicus hearing aids are customized to each user's actual loss and will 
            likely sound even better than the simulator.
          </p>
        </div>
      ) : <></>}
    </div>
  );
}

export default Instructions;