import logo from "../assets/images/audicus-logo.png"

const Header = () => {
  return (
    <div className="header">
      <a href="https://www.audicus.com">
        <img
          className="logo"
          src={logo}
          alt="audicus logo"
        />
      </a>
      <h1>Hearing Aid Simulator</h1>
    </div>
  )
};

export default Header;