import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useForm = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: {
      name: 'name',
      type: 'text',
      value: '',
      placeholder: 'Name',
      error: '',
    },
    email: {
      name: 'email',
      type: 'email',
      value: '',
      placeholder: 'Email Address',
      error: '',
    },
    phone: {
      name: 'phone',
      type: 'tel',
      value: '',
      placeholder: 'Phone Number',
      error: '',
    },
  });

  // const to =  convert the => JSON form data to a 
  // JSON activity that audicus API accepts
  const formData2Activity = (currentFormData) => {
    const fullName = currentFormData.name.value;

    const contactData = {
      firstname: fullName.split(' ')[0], 
      lastname: fullName.split(' ')[1] || '', 
      email: currentFormData.email.value,
      phone: currentFormData.phone.value
    };

    // initialize the activityData
    const activityData = { 
      contact: contactData, 
      activitytypeid: 'a028Y00001FEu9bQAD' 
    };

    return JSON.stringify(activityData);
  }

  // post data from form to Audicus API
  const postFormData = async (currentFormData) => {
    // convert form data to a JSON activity that Audicus API accepts
    const activityData = formData2Activity(currentFormData);
 
    try {
      const response = await fetch('https://api.audicus.com/sfprod/activity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: activityData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      analytics.track('Simulator FormSubmit', {});
      // set a cookie that expires in 30 days
      Cookies.set('formSubmitted', 'true', { expires: 30 });
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  // FORM VALIDATION

  const isValidName = (name) => {
    if (!name) return false;
    // Use a regular expression to check for valid characters
    const validCharsRegex = /^[A-Za-z0-9\s]+$/;
    return validCharsRegex.test(name);
  }

  const isValidEmail = (email) => {
    // Simple email validation (checks for @ and .)
    const re = /^(([^<>()\[\]\\.,;:\s@\x22]+(\.[^<>()\[\]\\.,;:\s@\x22]+)*)|(\x22.+\x22))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const isValidPhone = (phone) => {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phone.replace(/\D/g, '');
    // Define criteria for a valid phone number (adjust as needed)
    const minLength = 7;  // Minimum length of digits
    const maxLength = 14; // Maximum length of digits
    // Check if the cleaned phone number meets the criteria
    return (
      digitsOnly.length >= minLength &&
      digitsOnly.length <= maxLength
    );
  }

  const getFormError = (inputName, inputValue) => {
    let error = '';
    if (inputName === 'name') {
      if (!isValidName(inputValue)) {
        error = 'Please provide a valid name';
      }
    } else if (inputName === 'email') {
      if (!isValidEmail(inputValue)) {
        error = 'Please provide a valid email address';
      }
    } else if (inputName === 'phone') {
      if (!isValidPhone(inputValue)) {
        error = 'Please provide a valid phone number';
      }
    }
    return error;
  }

  const checkFormValidity = (data) => {
    const errors = Object.keys(data).map(inputName => data[inputName].error);
    let valid = true;
    errors.map(error => {
      if (error !== '') valid = false;
    });
    // since all fields are required
    Object.keys(data).map(inputName => {
      if (data[inputName].value === '') {
        valid = false;
      }
    });
    return valid;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const error = getFormError(name, value);
    const input = {
      ...formData[name],
      value,
      error,
      touched: true
    };
    setFormData({
      ...formData,
      [name]: input,
    });
  };

  useEffect(() => {
    if (formData) {
      setIsFormValid(checkFormValidity(formData));
    }
  }, [formData]);

  const createFormInput = (inputName) => (
    <div className="form-input-container" key={`form-input-${inputName}`}>
      <input 
        className={formData[inputName].error ? 'formInputError' : 'formInput'}
        type={formData[inputName].type}
        name={inputName}
        value={formData[inputName].value}
        onChange={handleInputChange}
        placeholder={formData[inputName].placeholder}
        required
      />
      <span className="formError">{formData[inputName].error}</span>
    </div>
  );

  return {
    formData,
    setFormData,
    formData2Activity,
    postFormData,
    createFormInput,
    isFormValid
  };
}

export default useForm;